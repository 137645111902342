import styled from "@emotion/styled";
import ReactPlayer from "react-player";

export const Main = styled.div`
  width: 80%;
  background: #000;
  color: #ffc600;
  margin: 0 auto;

  @media only screen and (max-width: 880px) {
    width: 95%;
  }
`;

export const MusicHeader = styled.div`
  font-size: 60px;
  text-transform: uppercase;
  border-bottom: 1px solid #ffc60040;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GenreContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  border-top: 1px solid #ffc60040;
  border-bottom: 1px solid #ffc60040;
`;

export const Genre = styled.div`
  font-size: 1.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 25%;
  aspect-ratio: 1 / 1;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 6px;

  &.selectedGenre {
    background: #ffc600;
    color: #000;
  }

  &:hover {
    cursor: pointer;
    background: #ffc600;
    color: #000;
  }

  @media only screen and (max-width: 1200px) {
    font-size: 1.1em;
    letter-spacing: 4px;
  }

  @media only screen and (max-width: 1050px) {
    flex: 1 0 30%;
  }

  @media only screen and (max-width: 575px) {
    flex: 1 0 40%;
    font-size: 14px;
    padding: 0px;
  }

  // /* Desktops, large screens */
  // @media only screen and (max-width: 1200px) {
  //   font-size: 1.4em;
  // }
  // /* Small screens, laptops */
  // @media only screen and (max-width: 1024px) {
  //   font-size: 1.3em;
  // }
  // /* iPads, Tablets */
  // @media only screen and (max-width: 768px) {
  //   font-size: 1.2em;
  // }
  // /* Mobile devices */
  // @media only screen and (max-width: 480px) {
  //   font-size: 1.1em;
  // }
`;

export const GenreContent = styled.div``;

export const Half = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  padding: 10px 10px;
`;

export const MusicListContainer = styled(Half)``;

export const SelectedSongContainer = styled(Half)`
  justify-content: space-between;
  border-top: 1px solid #ffc60040;
  border-bottom: 1px solid #ffc60040;
`;

export const Header = styled.div`
  color: #ffc60080;
  padding: 10px;
  width: 11em;
  text-align: center;
  font-size: 16px;

  span {
    color: #ffc600;
  }

  @media only screen and (max-width: 500px) {
    width: 7em;
  }
`;

export const SongName = styled.div`
  color: #ffc60080;
  width: 12em;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;

  span {
    color: #ffc600;
  }

  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

export const GroupedSongs = styled.div`
  max-height: 150px;
  overflow: auto;
  width: 100%;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #000;
    border: 1px solid #ffc600;
  }

  ::-webkit-scrollbar-thumb {
    background: #ffc600;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #ffc600;
  }
`;

export const Song = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;

  span {
    width: 12em;
  }

  &.selectedSong {
    background: #ffc600;
    color: #000;
  }

  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  padding-bottom: 10px;
  text-align: center;
`;

export const Description = styled.div`
  font-size: 13px;
  color: #ffc60080;
  padding-bottom: 10px;
  text-align: center;
`;

export const StyledReactPlayer = styled(ReactPlayer)`
  audio::-webkit-media-controls-panel {
    background-color: #ffc600;
    //filter: invert(100%);
  }

  audio::-webkit-media-controls-mute-button {
    background-color: #ffc600;
    border-radius: 50%;
  }

  audio::-webkit-media-controls-play-button {
    background-color: #ffc600;
    border-radius: 50%;
  }

  audio::-webkit-media-controls-play-button:hover {
    /* background-color: rgba(177,212,224, .7); */
  }

  audio::-webkit-media-controls-current-time-display {
    color: #000;
  }

  audio::-webkit-media-controls-time-remaining-display {
    color: #000;
  }

  audio::-webkit-media-controls-timeline {
    background-color: #ffc600;
    border-radius: 25px;
    margin-left: 10px;
    margin-right: 10px;
  }

  audio::-webkit-media-controls-volume-slider {
    background-color: #ffc600;
    border-radius: 25px;
  }

  audio {
    min-height: 20px;
    margin-bottom: 10px;
  }
  /* audio {
    filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
    width: 200px;
    height: 25px;
} */
`;

export const StyledCard = styled.div`
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 215px;
  border-radius: 4px;
  display: flex;
  flex-flow: column wrap;
  background-color: white;
  h1 {
    margin: 0;
    font-size: 3.4rem;
  }
  p {
    font-size: 1.4rem;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.6);
  }
  &.three {
    grid-column: span 3;
  }
`;

export const StyledContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin: auto;
  max-width: 1400px;
  padding: 20px;
  border-top: 1px solid #ffc60040;
  border-bottom: 1px solid #ffc60040;

  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
`;
